import React from "react"
import PropTypes from "prop-types"
import { Box, Stack, Typography } from "@mui/material"
import { ContentWrapper } from "../components"

import { graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import { MarkdownContainer } from "./blog/_article"

const ContactPageTemplate = ({ data }) => {
  const { title, subtitle, items } = data.markdownRemark.frontmatter

  return (
    <ContentWrapper title={title} subtitle={subtitle}>
      <Box minHeight="35vh">
        <Stack
          direction="row"
          flexWrap="wrap"
          justifyContent="stretch"
          mx="-1rem"
          pt={3}
        >
          {items.map((item, i) => {
            return (
              <Stack
                key={`contact-item-${i}`}
                direction="row"
                flexGrow="1"
                mb="2rem!important"
                mx="1rem"
                boxShadow="0px 0px 10px rgba(0, 0, 101, 0.1)"
                borderRadius="10px"
                alignItems="center"
                spacing={2}
                p={4}
              >
                <GatsbyImage
                  image={item.icon.childImageSharp.gatsbyImageData}
                  alt={item.title}
                  style={{ maxWidth: "45px" }}
                  objectFit="contain"
                />
                <Box sx={{ "& p": { my: 0 } }}>
                  <Typography color="primary" fontWeight="bold">
                    {item.title}
                  </Typography>
                  <MarkdownContainer markdown={item.text} />
                </Box>
              </Stack>
            )
          })}
        </Stack>
      </Box>
    </ContentWrapper>
  )
}

ContactPageTemplate.propTypes = {
  data: PropTypes.object.isRequired,
}

export default ContactPageTemplate

export const query = graphql`
  fragment ContactPage on MarkdownRemarkFrontmatter {
    title
    subtitle
    items {
      title
      text
      icon {
        id
        childImageSharp {
          gatsbyImageData
        }
      }
    }
  }
`
