import React from "react"
import { graphql } from "gatsby"
import Layout from "../../layouts/en"
import ContactPageTemplate from "../../templates/contact-page"

const ContactPageEnglish = props => {
  const { title, subtitle } = props.data.markdownRemark.frontmatter

  return (
    <Layout location={props.location} title={title} description={subtitle}>
      <ContactPageTemplate {...props} />
    </Layout>
  )
}

export default ContactPageEnglish

export const pageQuery = graphql`
  query ContactPageEnglish {
    markdownRemark(
      fileAbsolutePath: { regex: "/static-pages/contact/index.en.md/" }
    ) {
      frontmatter {
        ...ContactPage
      }
    }
  }
`
